@charset "utf-8";

/*	CSS for customized radio buttons and check boxes */

/* page styles */

fieldset {
  padding: 0 15px 3em;
  border: 0;
}

legend {
  font-size: 1.4em;
  font-weight: bold;
  padding: .2em 5px;
}

/*	wrapper divs */
.custom-checkbox, .custom-radio {
  position: relative;
}

/* input, label positioning */
/* wrapper divs */
.custom-checkbox, .custom-radio {
  position: relative;
  color: black;
  font-size: 13px;
  font-family: helvetica, sans-serif;
  text-transform: none;
}

/* input, label positioning */
.custom-checkbox input,
.custom-radio input {
  position: absolute;
  left: -9999999px;
  top: 3px;
  margin: 0;
  z-index: 0;

}

.custom-checkbox label,
.custom-radio label {
  display: inline-block;
  position: relative;
  z-index: 1;
  line-height: 1.4em;
  padding: .2em 0 0 30px;
  margin: 0 0 .3em;
  cursor: pointer;
}

/* states */
//.custom-checkbox label { background: url(../images/checkbox.png) no-repeat; }

//.custom-radio label { background: url(../images/radiobutton.gif) no-repeat; }

.custom-checkbox label.focus,
.custom-radio label.focus {
  outline: 1px dotted #ccc;
}

.custom-checkbox label, .custom-radio label {
  &:before {
    //background-position: -10px -14px;
    font-family: FontAwesome, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    text-align: center;
    font-size: 15px;
    color: #ddd;
    padding-top: .2em;
  }

  &.focus,
  &.hover {
    &:before {
      color: #0a5593;
    }
  }

  &.checkedHover,
  &.checkedFocus,
  &.checked {
    &:before {
      color: #3395D3;
    }
  }
}

.custom-checkbox {
  position: relative;
  label {
    &:before {
      content: '';
    }
    &.focus:before,
    &.hover:before {
      content: '';
    }
    &.checked:before,
    &.checkedHover:before,
    &.checkedFocus:before {
      content: '';
    }
  }
}

.custom-radio {
  &:before {
    content: '';
  }
  &.focus:before,
  &.hover:before {
    content: '';
  }
  &.checked:before,
  &.checkedHover:before,
  &.checkedFocus:before {
    content: '';
  }
}

// ouwe meuk
.custom-checkbox label.hover,
.custom-checkbox label.focus,
.custom-radio label.hover,
.custom-radio label.focus {
  //background-position: -10px -114px;
}

.custom-checkbox label.checked,
.custom-radio label.checked {
  //background-position: -10px -214px;
}

.custom-checkbox label.checkedHover,
.custom-checkbox label.checkedFocus {
  //background-position: -10px -314px;
}
